<template>
  <!-- 判断 -->
  <div class="estimateWrap" :id="'question' + list.seq">
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          {{ list.label }}. <span class="detailMate" v-if="is_stuff == 1">资料</span>
          <span class="detailCate">判断题</span><span class="detailScore">[{{list.question_score}}分]</span>
          <div class="detailContent" v-html="list.title"></div>
        </div>
      </div>
      <div class="detailOption">
        <el-radio-group v-model="list.radio" style="width: 100%;">
          <el-radio
            :label="item.option_mark"
            v-for="(item, index) in list.question_option"
            :key="index"
          >
            <i class="optionIcon"></i>{{ item.option_mark }}. <div class="optionContent" v-html="item.option_content"></div>
          </el-radio>
        </el-radio-group>
      </div>
<!--      <Point :thought_dial="list.thought_dial" v-if="list.thought_dial"></Point>-->
    </div>
<!--    <i-->
<!--      class="el-icon-star-on itemGreyStar"-->
<!--      v-if="list.iscollect == 0"-->
<!--      @click="collect(0)"-->
<!--    ></i>-->
<!--    <i-->
<!--      class="el-icon-star-on itemYellowStar"-->
<!--      v-if="list.iscollect == 1"-->
<!--      @click="collect(1)"-->
<!--    ></i>-->
  </div>
</template>

<script>
  // import { updatecollect,delcollect } from "../assets/api";
// import Point from "./com/Point.vue"; //思路点拨
export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {
  },
  data() {
    return {
      radio: "",
    };
  },
  mounted() {},
  methods: {

  },
};
</script>

<style scoped lang=""></style>
