<template>
  <!-- 论述 -->
  <div class="discussWrap" :id="'question' + list.seq">
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          {{ list.label
          }}. <span class="detailMate" v-if="is_stuff == 1">资料</span>
          <span class="detailCate">论述题</span><span class="detailScore">[{{ list.question_score }}分]</span>
          <div class="detailContent" v-html="list.title"></div>
        </div>
      </div>
      <div class="detailOption">
        <div class="optionWrap">
          <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="list.radio"
                  @paste.native.capture.prevent="inputPress"
                  v-if="isDisable"
                  class="mb20"
          ></el-input>
          <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="list.radio"
                  v-else
                  class="mb20"
          ></el-input>
          <template v-if="ipShow">
           <el-upload
                    class="upload-demo"
                    :data="upld"
                    :action="ip"
                    v-if="list.isExam == 1 || no_screen_cuts == 0"
                    :headers="{
                      'Uxb-User-Agent': 'webVersion:v1.0.0',
                    }"
                    accept=" .jpg, .mp4, .JPG, .MP4"
                    :file-list="list.user_pic"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :on-success="sucesspic"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload" multiple
            >
              <el-button size="small" type="primary">上传附件</el-button>
              <div slot="tip" class="el-upload__tip" style="color: red">视频文件格式为mp4，单个视频文件大小不超100MB；拍照文件格式为jpg，单个拍照文件大小不超20MB</div>
            </el-upload>
          </template>
          <template v-else>
              <el-button  size="small" type="primary" @click="httpReq(list.label)">上传附件</el-button>
              <div slot="tip" class="el-upload__tip"  style="color: red">视频文件格式为mp4，单个视频文件大小不超100MB；拍照文件格式为jpg，单个拍照文件大小不超20MB</div>
              <ul class='tyupic'>
                <li v-for="(item,index) in list.user_pic" :key="index"><span  @click="preshow(item)"><i class="el-icon-document"></i>
                  {{item.name}}</span><i class="el-icon-delete" @click="delshow(item,index)"></i></li>
              </ul>
            <el-dialog
                    title="失败文件"
                    :visible.sync="dialogVisibles"
                    width="30%">
              <p style="padding-bottom: 20px">以下文件上传失败：</p>
              <p style="line-height: 24px" v-for="(it,ins) in list.user_err" :key="ins">{{it}}</p>
            </el-dialog>
          </template>
          <el-dialog :visible.sync="dialogVisible" title="预览" width="800px">
            <div  style="width:760px;height:500px;text-align: center;line-height:500px">
              <video v-if="viddo" :src="dialogImageUrl" controls width="100%" height="100%"></video>
              <img v-else  :src="dialogImageUrl" alt="" style="max-width:100%;max-height:100%" /></div>
          </el-dialog>
        </div>
      </div>
      <!--      <Point :thought_dial="list.thought_dial" v-if="list.thought_dial"></Point>-->
    </div>
    <!--    <i-->
    <!--      class="el-icon-star-on itemGreyStar"-->
    <!--      v-if="list.iscollect == 0"-->
    <!--      @click="collect(0)"-->
    <!--    ></i>-->
    <!--    <i-->
    <!--      class="el-icon-star-on itemYellowStar"-->
    <!--      v-if="list.iscollect == 1"-->
    <!--      @click="collect(1)"-->
    <!--    ></i>-->
  </div>
</template>
<script>
  import {QWebChannel} from '../assets/qwebchannel'
  import { changeStr, pinjie, objKeySort } from "../../../../assets/js/sign";
  import Md5 from "md5";
  import {getCookie} from "../../../../assets/js/cookie";
  import axios from 'axios'
  import { Base64 } from 'js-base64';
  var nativeObj
  var data = []
  export default {
    props: {
      list: Object,
      is_stuff: Number,
    },
    components: {},
    watch: {
      dialogVisible: {
            handler: function(n,o) {
              if(!n){
                this.dialogImageUrl=''
              }

            }
        }
    },
    data() {
      return {
        dialogVisibles:false,
        viddo:false,
        dialogImageUrl: "",
        dialogVisible: false,
        upld: {
          file_path:'',
          keyid:getCookie('sid'),
          siteid: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
          bucket_type: 2,
          up_config: '',
          uploadfiletype: 1,
          operation_mode: 1,
          stime: "",
          sign: "",
        },
        isDisable: false,
        no_screen_cuts: 0, //判断是否开启作弊1开启，0不开启
        ip:'',
        ipShow:true
      };
    },
    mounted() {
      if(this.$parent.data1){
        this.$parent.data1.map(item=>{
          this.$set(item,'showbtn',true)
        })
      }
      window.addEventListener("drop", (e) => {
        e.preventDefault();
        return false;
      });
      this.isDisable = this.$parent.isDisable;
      if (this.$route.query.no_screen_cuts != undefined) {
        this.no_screen_cuts = this.$route.query.no_screen_cuts;
      }
      let ips = JSON.parse(sessionStorage.getItem('prevent')).ip
      this.ipShow = ips?false:true
      this.ip =  ips?'http://'+ips+':8000/apis/upload ':'/api/uploader/v1/upload_file'
      console.log(this.ip,111111)
      this.upld.up_config = ips?2:1
      if(!this.ipShow){
        setTimeout(()=>{
          if (typeof qt != 'undefined') {
            window.channel = new QWebChannel(qt.webChannelTransport, (channel) => {
              nativeObj = channel.objects.nativeObj
              console.log(nativeObj,window.qt,12312331)
              this.qt()
            })
          }else{
            alert("qc对象未获取到");
          }
        },0)
      }
    },
    methods: {
      httpReq(option){
        console.log(this.list.label,option,99999)
        data=[]
        this.upld.sign = "";
        this.upld.stime = "";
        this.upld.stime = parseInt(new Date().getTime() / 1000);
        this.upld.sign = Md5(changeStr(pinjie(objKeySort(this.upld))))
        let ips = JSON.parse(sessionStorage.getItem('prevent')).ip+':8000'
        nativeObj.uploadFile(ips,this.upld,option);

      },
      qt(){
        var _this = this

        nativeObj.uploadFinished.connect(function (text) {
          var jsonData = JSON.parse(text);
          console.log(jsonData,22223344121)
          if(jsonData.code==0){
            jsonData.data.name = jsonData.data.file_name.split('/')[jsonData.data.file_name.split('/').length-1]
            _this.$parent.data1.map(item=>{
              if(item.label==jsonData.data.seq){
                  item.user_pic.push({path:jsonData.data.uuid,name:jsonData.data.name,url:jsonData.data.inline_url})
                }
              })
          }else{
            console.log(jsonData,2222)
            _this.$parent.data1.map(item=>{
              if(item.label==jsonData.data.seq){
                if(jsonData.data.fileName){
                  data.push(jsonData.data.fileName)
                  _this.$set(item,'user_err',data)
                }
              }
            })
            _this.dialogVisibles = true
          }
          console.log(_this.list.label,jsonData,6666)
        })
        //连接WebObject类的deleteFinished信号
        nativeObj.delteFinished.connect(function (text) {
          var jsonData = JSON.parse(text);
          console.log(jsonData);
        })
        //上传结束全部
        nativeObj.uploadAllFinished.connect(function (text) {
          // _this.$parent.data1.map(item=>{
          //   if(item.label==text){
          //     _this.$set(item,'showbtn',true)
          //   }
          // })
          console.log(text,1213123123123123);
        })
      },
      preshow(val){//预览
        let ips = JSON.parse(sessionStorage.getItem('prevent')).ip
          nativeObj.showMedia('http://'+ips+':8000/'+val.url);
      },
      delshow(val,index){
        let ips = JSON.parse(sessionStorage.getItem('prevent')).ip+':8000'
        nativeObj.deleteFile(ips, Base64.encode(val.url));
        this.list.user_pic.splice(index,1)
      },
      inputPress() {
        return false;
      },
      // collect(type) {
      //   if (type == 0) {
      //     let params = {
      //       column_id: sessionStorage.getItem("column_id"),
      //       collecttype: 1,
      //       question_paper_id: this.list.question_id,
      //     };
      //     updatecollect(params).then((res) => {
      //       if (res.code == 0) {
      //         this.list.collect_id = res.data.collect_id;
      //         this.list.iscollect = 1;
      //         this.$message({
      //           message: "收藏成功",
      //           type: "success",
      //         });
      //       } else {
      //         this.$message.error("收藏失败");
      //       }
      //     });
      //   } else {
      //     let params = {
      //       column_id: sessionStorage.getItem("column_id"),
      //       collecttype: 1,
      //       collect_id: this.list.collect_id,
      //     };
      //     delcollect(params).then((res) => {
      //       if (res.code == 0) {
      //         this.list.collect_id = 0;
      //         this.list.iscollect = 0;
      //         this.$message({
      //           message: "取消收藏成功",
      //           type: "success",
      //         });
      //       } else {
      //         this.$message.error("取消收藏失败");
      //       }
      //     });
      //   }
      // },
      //上传图片
      sucesspic(response,file,fileList) {
        console.log(response,file,fileList,121212)
        if (fileList.every(item => item.status == 'success')) {
          fileList.map(item => {
            if(item.response) {
              this.list.user_pic.push({
                url:item.response.data.inline_url,
                path: item.response.data.uuid,
                name:item.name
              })
            }
          })
        }
      },
      handleRemove(file, fileList) {
        // if (this.list.chang_img) {
        //   this.list.chang_img.push(file.path);
        // } else {
        //   this.$set(this.list, "chang_img", []);
        //   this.list.chang_img.push(file.path);
        // }
        this.list.user_pic.forEach((item, index) => {
          if (file.url == item.url) {
            this.list.user_pic.splice(index, 1);
          }
        });

        let posts = {
          url:Base64.encode(file.url)
        }
        let ips = JSON.parse(sessionStorage.getItem('prevent')).ip
        axios.post('http://'+ips+':8000/apis/delete_file',posts,{ headers:{
            }}).then((res)=>{

        })
      },
      handlePictureCardPreview(file) {
        this.viddo = (file.url.indexOf('.mp4')>-1||file.url.indexOf('.MP4')>-1)?true:false
        if(file.url.includes('http://')){
          this.dialogImageUrl ='http://'+JSON.parse(sessionStorage.getItem('prevent')).ip+':8000' + file.url.split('8008')[1];
        }else{
          this.dialogImageUrl = file.url;
        }

        this.dialogVisible = true;
      },
      // 文件上传的状态
      beforeUpload(file) {
        let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
        var reg = new RegExp('[\\\\/:*?"<>|]');
        let errors = reg.test(file.name);
        if (errors) {
          this.$message.warning(
                  "文件名不能包含“?”、“、”、“╲”、“/”、“*”、““”、“”“、“”、“|”等特殊字符"
          );
          return false;
        }
        var leng = file.name.substr(0, file.name.lastIndexOf(".")).length;
        if (leng > 255) {
          this.$message.warning("文件名太长");
          return false;
        }
        if (leng == 0) {
          this.$message.warning("文件名不能为空");
          return false;
        }
        const extension1 = testmsg === "jpg";
        const extension4 = testmsg === "mp4";
        const extension2 = testmsg === "JPG";
        const extension3 = testmsg === "MP4";
        console.log(extension1,extension2,extension3,extension4,1231123123)
        const isLimit = file.size / 1024 / 1024;
        if (!extension1 && !extension2&& !extension3&& !extension4) {
          this.$message.warning("您只能上传以下类型文件：.jpg, .mp4,.JPG, .MP4!");
        }
        if (extension1||extension2) {
          if (isLimit > 20) {
            this.$message.warning("图片大小不得超过20M");
            return false;
          } else {
            this.upld.file_path = '';
            this.upld.sign = "";
            this.upld.stime = "";
            // this.upld.file_path =file.name
                    this.upld.stime = parseInt(new Date().getTime() / 1000);
            this.upld.sign = Md5(changeStr(pinjie(objKeySort(this.upld))));
          }
        }else if(extension4||extension3){
          if (isLimit > 100) {
            this.$message.warning("视频大小不得超过100M");
            return false;
          } else {
            this.upld.file_path = '';
            this.upld.sign = "";
            this.upld.stime = "";
            // this.upld.file_path =file.name
            this.upld.stime = parseInt(new Date().getTime() / 1000);
            this.upld.sign = Md5(changeStr(pinjie(objKeySort(this.upld))));
          }
        }
        return extension1 || extension2 || extension3 || extension4;
      },
      // 文件上传限制
      handleExceed(files, fileList) {
        this.$message.warning(`最多上传 ${fileList.length} 个文件`);
      },
    },
  };
</script>

<style scoped lang="scss">
  .tyupic{
    cursor: pointer; width: 100%;
    li{
      display:flex; justify-content: space-between; font-size: 14px;  line-height: 30px; padding-right: 150px;
    }
  }
</style>
