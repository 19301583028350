<template>
  <!-- 资料 -->
  <div class="materialWrap">
    <template v-if="$route.query.shitiselsect > 0">
      <div class="materialTitle" v-if="list.isHow">
        <div class="titleTop">
          <div>
            <i class="iconfont icon-icon_yd"></i>
            阅读材料，回答下面的题目
          </div>
          <div class="ejectBtn" @click="eject">
            <i class="iconfont icon-icon_tc"></i>
            弹出
          </div>
        </div>
        <div class="titleBottom" v-html="list.content"></div>
      </div>
    </template>
    <template v-else>
      <div class="materialTitle">
        <div class="titleTop">
          <div>
            <i class="iconfont icon-icon_yd"></i>
            阅读材料，回答下面的题目
          </div>
          <div class="ejectBtn" @click="eject">
            <i class="iconfont icon-icon_tc"></i>
            弹出
          </div>
        </div>
        <div class="titleBottom" v-html="list.content"></div>
      </div>
    </template>
    <div v-for="(item, index) in list.children" :key="index">
      <!-- 单选 -->
      <Radio v-if="item.retype == 1" :list="item" :is_stuff="1"></Radio>
      <!-- 多选 -->
      <Checkbox
        v-if="item.retype == 2 || item.retype == 3"
        :list="item"
        :is_stuff="1"
      ></Checkbox>
      <!-- 判断 -->
      <Estimate v-if="item.retype == 5" :list="item" :is_stuff="1"></Estimate>
      <!-- 论述 -->
      <template v-if="$route.query.shitiselsect > 0">
        <Discuss
          v-if="
            item.retype == 4 && $route.query.shitiselsect == item.question_seq
          "
          :list="item"
          :is_stuff="1"
        ></Discuss>
      </template>
      <template v-else>
        <Discuss v-if="item.retype == 4" :list="item" :is_stuff="1"></Discuss>
      </template>
      <p class="line" v-if="index != list.children.length - 1"></p>
    </div>
    <el-dialog title="材料详情" :visible.sync="centerDialogVisible" width="30%">
      <div v-html="list.content" class="fontSize14 lineH22"></div>
    </el-dialog>
  </div>
</template>

<script>
import Radio from "./Radio"; // 单选
import Checkbox from "./Checkbox"; // 多选
import Estimate from "./Estimate"; // 判断
import Discuss from "./Discuss"; // 论述

export default {
  props: {
    list: Object,
  },
  components: { Radio, Checkbox, Estimate, Discuss },
  data() {
    return {
      centerDialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    // 弹出
    eject() {
      // var blankWindow = window.open(
      //   "",
      //   "",
      //   "width=700, height=500, top=100,left=100, scrollbars=yes, resizable=no"
      // );
      // var html = `<p>${this.list.content}</p>`;
      // blankWindow.document.write(html);
      // blankWindow.focus();
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style scoped lang=""></style>
