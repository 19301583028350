<template>
  <div :class="student_info!=1?'answerWrap':'answerWrap answerWraps'">
    <div class="answerBtn" @click="upOrDown">
      <span style="display: flex;align-items:center">
         <i class="leftIcon"
         ><img src="../../assets/images/icon_dp.png" alt=""
         /></i>
      答题卡
      </span>
      <span class="answerBtnzdsm" @click="zdsmshow" v-if="prevent.matters!=''">作答说明<i class="iconfont icon-zdsm"></i></span>
    </div>
    <div class="answerList scroll">
      <!--      小米实验题-->
      <template v-if="$route.query.shitiselsect > 0">
        <div class="ansQue" v-if="type">
          <div v-for="(ite, inde) in answerData" :key="inde">
            <h2 class="fontSize16 pb14" v-if="ite.isHow">{{ ite.part_title }}</h2>
            <div
              style="display: inline"
              v-for="(item, index) in ite.children"
              :key="index"
            >
              <template v-if="!item.children">
                <em
                  :class="{
                    checkColor:
                      ((item.retype == 1 || item.retype == 5) && item.radio) ||
                      ((item.retype == 2 || item.retype == 3) &&
                        item.radio.length > 0) ||
                      (item.retype == 4 &&
                        (item.radio || item.user_pic.length > 0)),
                  }"
                  v-if="
                    $route.query.shitiselsect != 0 &&
                    item.question_seq == $route.query.shitiselsect
                  "
                  @click="jump(item.seq)"
                >
                  {{ item.question_seq }}
                </em>

              </template>
              <template v-if="item.children">
                <strong
                  v-for="(item1, index1) in item.children"
                  :key="index1"
                  style="font-weight: normal"
                >
                  <em
                    :class="{
                      checkColor:
                        ((item1.retype == 1 || item1.retype == 5) &&
                          item1.radio) ||
                        ((item1.retype == 2 || item1.retype == 3) &&
                          item1.radio.length > 0) ||
                        (item1.retype == 4 &&
                          (item1.radio || item1.user_pic.length > 0)),
                    }"
                    v-if="
                      $route.query.shitiselsect != 0 &&
                      item1.question_seq == $route.query.shitiselsect
                    "
                    @click="jump(item1.seq)"
                  >
                    {{ item1.question_seq }}
                  </em>

                </strong>
              </template>
            </div>
          </div>
        </div>
        <div class="ansQue" v-else>
          <span v-for="(item, index) in answerData" :key="index">
            <template v-if="!item.children">
              <em
                :class="{
                  checkColor:
                    ((item.retype == 1 || item.retype == 5) && item.radio) ||
                    ((item.retype == 2 || item.retype == 3) &&
                      item.radio.length > 0) ||
                    (item.retype == 4 &&
                      (item.radio || item.user_pic.length > 0)),
                }"
                v-if="
                  $route.query.shitiselsect != 0 &&
                  item.question_seq == $route.query.shitiselsect
                "
                @click="jump(item.seq)"
              >
                {{ item.question_seq }}
              </em>

            </template>
            <template v-if="item.children">
              <strong
                v-for="(item1, index1) in item.children"
                :key="index1"
                style="font-weight: normal"
              >
                <em
                  :class="{
                    checkColor:
                      ((item1.retype == 1 || item1.retype == 5) &&
                        item1.radio) ||
                      ((item1.retype == 2 || item1.retype == 3) &&
                        item1.radio.length > 0) ||
                      (item1.retype == 4 &&
                        (item1.radio || item1.user_pic.length > 0)),
                  }"
                  v-if="
                    $route.query.shitiselsect != 0 &&
                    item1.question_seq == $route.query.shitiselsect
                  "
                  @click="jump(item1.seq)"
                >
                  {{ item1.question_seq }}
                </em>

              </strong>
            </template>
          </span>
        </div>
      </template>
      <!--      其他试题-->
      <template v-else>
        <div class="ansQue" v-if="type">
          <div v-for="(ite, inde) in answerData" :key="inde">
            <h2 class="fontSize16 pb14">{{ ite.part_title }}</h2>
            <div
              style="display: inline"
              v-for="(item, index) in ite.children"
              :key="index"
            >
              <template v-if="!item.children">
                <em
                  :class="{
                    checkColor:
                      ((item.retype == 1 || item.retype == 5) && item.radio) ||
                      ((item.retype == 2 || item.retype == 3) &&
                        item.radio.length > 0) ||
                      (item.retype == 4 &&
                        (item.radio || item.user_pic.length > 0)),
                  }"
                  @click="jump(item.seq)"
                >
                  {{ item.question_seq }}
                </em>
              </template>
              <template v-if="item.children">
                <strong
                  v-for="(item1, index1) in item.children"
                  :key="index1"
                  style="font-weight: normal"
                >
                  <em
                    :class="{
                      checkColor:
                        ((item1.retype == 1 || item1.retype == 5) &&
                          item1.radio) ||
                        ((item1.retype == 2 || item1.retype == 3) &&
                          item1.radio.length > 0) ||
                        (item1.retype == 4 &&
                          (item1.radio || item1.user_pic.length > 0)),
                    }"
                    @click="jump(item1.seq)"
                  >
                    {{ item1.question_seq }}
                  </em>

                </strong>
              </template>
            </div>
          </div>
        </div>
        <div class="ansQue" v-else>
          <span v-for="(item, index) in answerData" :key="index">
            <template v-if="!item.children">
              <em
                :class="{
                  checkColor:
                    ((item.retype == 1 || item.retype == 5) && item.radio) ||
                    ((item.retype == 2 || item.retype == 3) &&
                      item.radio.length > 0) ||
                    (item.retype == 4 &&
                      (item.radio || item.user_pic.length > 0)),
                }"
                @click="jump(item.seq)"
              >
                {{ item.question_seq }}
              </em>
            </template>
            <template v-if="item.children">
              <strong
                v-for="(item1, index1) in item.children"
                :key="index1"
                style="font-weight: normal"
              >
                <em
                  :class="{
                    checkColor:
                      ((item1.retype == 1 || item1.retype == 5) &&
                        item1.radio) ||
                      ((item1.retype == 2 || item1.retype == 3) &&
                        item1.radio.length > 0) ||
                      (item1.retype == 4 &&
                        (item1.radio || item1.user_pic.length > 0)),
                  }"
                  @click="jump(item1.seq)"
                >
                  {{ item1.question_seq }}
                </em>

              </strong>
            </template>
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { getCookie } from '@/assets/js/cookie.js';
export default {
  props: {
    answerTotal: Array,
    type: Boolean,
    student_info:String,
    prevent:Object
  },
  watch: {
    answerTotal: {
      handler() {
        this.answerData = [];
        console.log(this.answerTotal);
        this.answerTotal.forEach((item) => {
          this.answerData.push(item);
          // if (item.stuff_id == 0) {
          //   this.answerData.push(item);
          // } else {
          //   item.list.forEach((i) => {
          //     this.answerData.push(i);
          //   });
          // }
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      answerShow: false,
      answerData: [],
      siteid:''
    };
  },
  mounted() {
    this.siteid=getCookie('siteid')
  },
  methods: {
    zdsmshow(){
      this.$parent.zdsmVisible=true
    },
    upOrDown() {
      this.answerShow = !this.answerShow;
    },
    jump(data) {
      let t = document.querySelector("#question" + data).offsetTop;
      window.scrollTo(0, t - 88);
    },
  },
};
</script>

<style scoped lang="scss">
  .answerWrap {
    width: 240px; flex: 1;  height: calc(100% - 420px);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05); position: relative;background: #fff;
    left: 0; margin:0;
    .answerBtn {
      width: 240px;
      height: 40px;
      padding: 10px 0;
      box-sizing: border-box;
      display: flex; font-weight: bold;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      line-height: 22px;
      color: #333;
      border-radius: 8px 8px 0 0;
      background: #F3F7FF;
      cursor: pointer;
      padding: 0 0 0 20px;
      .leftIcon {
        margin-right: 5px; margin-top: -4px;
      }
      .answerBtnzdsm{
        font-size: 14px;
        line-height: 22px;
        color: #5B82FC;
        padding-right: 20px;
        cursor: pointer;
        i{
          font-size: 12px;
          margin-left:4px;
        }
      }
    }

    .answerList {
      width: 100%;
      height: calc(100% - 40px);
      padding: 0px 0px 10px 18px;
      box-sizing: border-box;
      box-shadow:none;
      overflow-y: auto;
      .answerListoul {
        padding-top: 20px;

        li {
          display: inline-block;
          *display: inline;
          *zoom: 1;
          position: relative;
          font: 14px/34px 'Microsoft YaHei';
          color: #888888;
          margin-right: 20px;
          padding-left: 16px;

          &:first-child::after {
            clear: both;
            content: '';
            position: absolute;
            left: 0;
            top: 12px;
            width: 10px;
            height: 10px;
            background: #42c9ab;
            border-radius: 50%;
          }

          &:nth-child(2)::after {
            clear: both;
            content: '';
            position: absolute;
            left: 0;
            top: 12px;
            width: 10px;
            height: 10px;
            background: #fe4d67;
            border-radius: 50%;
          }

          &:nth-child(3)::after {
            clear: both;
            content: '';
            position: absolute;
            left: 0;
            top: 12px;
            width: 10px;
            height: 10px;
            background: #507fff;
            border-radius: 50%;
          }

          &:nth-child(4)::after {
            clear: both;
            content: '';
            position: absolute;
            left: 0;
            top: 12px;
            width: 10px;
            height: 10px;
            background: #cecece;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .answerWraps{
    height:100%;
  }
</style>
