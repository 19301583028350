<template>
  <div>
    <div class="topFix">
      <h2 class="lineH38">{{ prevent.title }}</h2>
      <div>
        <span
          class="lineH38 fontSize16"
          style="display: inline-block; vertical-align: middle"
          v-if="info1.time_limit > 0"
          ><i class="iconfont icon-icon_side_time mr10"></i
          >{{ countDown }}</span
        >
        <span
          class="lineH38 fontSize20"
          style="display: inline-block; vertical-align: middle; font-weight: bold"
          v-if="info1.time_limit == -1"
          ><i style="font-weight: normal; font-size: 16px">剩余时间</i> {{ countDowns }}</span
        >
        <el-button
          :type="butshow ? 'info' : 'primary'"
          class="ml30"
          @click="submit()"
          :disabled="butshow"
          >交卷</el-button
        >
      </div>
    </div>
    <div class="contMain">
      <aside>
        <dl class="stinfo" v-if="student_info != 1">
          <dt>
            考生信息
          </dt>
          <dd>
            <div v-if="student_info.face_img" class="pic">
              <img width="100%" height="100%" :src="student_info.face_img" alt="" />
            </div>
            <div v-else class="pic">
              <img src="./assets/images/wu.png" width="100%" height="100%" alt="">
            </div>
            <div class="picxx">
              <span>姓　名：<i>{{ student_info.name }}</i></span
                    >
              <span v-if="student_info.job_num"
              >{{cook==52||cook==56?'小米I D':'学　号'}}：<i>{{ student_info.job_num }}</i></span
              >
              <span v-if="student_info.ticket">准考证：<br><i>{{
            student_info.ticket}}</i></span>

            </div>
            <span

          </dd>
        </dl>
        <AnswerSheet :answerTotal="this.data1" :type="isBftrue" :student_info="student_info" :prevent="prevent"></AnswerSheet>
      </aside>
      <main>
        <!--      小米实验题-->
        <div class="mainWrap" v-if="shitiselsect > 0">
          <template v-if="isBftrue">
            <div v-for="(item1, index1) in data1" :key="index1">
              <!-- 部分 -->
              <template v-if="item1.isHow">
                <h3 class="types">
                  <b>{{ item1.part_title }}</b>
                </h3>
                <p class="intr" v-if="item1.description">
                  {{ item1.description }}
                </p>
              </template>
              <div class="testQuestions" v-if="item1.isHow">
                <div v-for="(item, index) in item1.children" :key="index">
                  <!-- 单选 -->
                  <Radio
                    class="itemWrap"
                    v-if="item.is_stuff == 0 && item.retype == 1"
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Radio>
                  <!-- 多选 -->
                  <Checkbox
                    class="itemWrap"
                    v-if="
                      (item.is_stuff == 0 && item.retype == 2) ||
                      (item.is_stuff == 0 && item.retype == 3)
                    "
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Checkbox>
                  <!-- 判断 -->
                  <Estimate
                    class="itemWrap"
                    v-if="item.is_stuff == 0 && item.retype == 5"
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Estimate>
                  <!-- 论述 -->
                  <Discuss
                    class="itemWrap"
                    v-if="
                      item.is_stuff == 0 &&
                      item.retype == 4 &&
                      shitiselsect == item.question_seq
                    "
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Discuss>
                  <!-- 资料 -->
                  <Materials
                    class="itemWrap"
                    v-if="item.is_stuff == undefined"
                    :list="item"
                  ></Materials>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="testQuestions">
              <div v-for="(item, index) in data1" :key="index">
                <!-- 单选 -->
                <Radio
                  class="itemWrap"
                  v-if="item.is_stuff == 0 && item.retype == 1"
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Radio>
                <!-- 多选 -->
                <Checkbox
                  class="itemWrap"
                  v-if="
                    (item.is_stuff == 0 && item.retype == 2) ||
                    (item.is_stuff == 0 && item.retype == 3)
                  "
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Checkbox>
                <!-- 判断 -->
                <Estimate
                  class="itemWrap"
                  v-if="item.is_stuff == 0 && item.retype == 5"
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Estimate>
                <!-- 论述 -->
                <Discuss
                  class="itemWrap"
                  v-if="
                    item.is_stuff == 0 &&
                    item.retype == 4 &&
                    shitiselsect == item.question_seq
                  "
                  :list="item"
                  :is_stuff="item.is_stuff"
                  :isDisable="isDisable"
                ></Discuss>
                <!-- 资料 -->
                <Materials
                  class="itemWrap"
                  v-if="item.is_stuff == undefined"
                  :list="item"
                ></Materials>
              </div>
            </div>
          </template>
        </div>
        <!--      其他试题-->
        <div class="mainWrap" v-else>
          <template v-if="isBftrue">
            <div v-for="(item1, index1) in data1" :key="index1">
              <!-- 部分 -->
              <h3 class="types">
                <b>{{ item1.part_title }}</b>
              </h3>
              <p class="intr" v-if="item1.description">
                {{ item1.description }}
              </p>
              <div class="testQuestions">
                <div v-for="(item, index) in item1.children" :key="index">
                  <!-- 单选 -->
                  <Radio
                    class="itemWrap"
                    v-if="item.is_stuff == 0 && item.retype == 1"
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Radio>
                  <!-- 多选 -->
                  <Checkbox
                    class="itemWrap"
                    v-if="
                      (item.is_stuff == 0 && item.retype == 2) ||
                      (item.is_stuff == 0 && item.retype == 3)
                    "
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Checkbox>
                  <!-- 判断 -->
                  <Estimate
                    class="itemWrap"
                    v-if="item.is_stuff == 0 && item.retype == 5"
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Estimate>
                  <!-- 论述 -->
                  <Discuss
                    class="itemWrap"
                    v-if="item.is_stuff == 0 && item.retype == 4"
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Discuss>
                  <!-- 资料 -->
                  <Materials
                    class="itemWrap"
                    v-if="item.is_stuff == undefined"
                    :list="item"
                  ></Materials>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="testQuestions">
              <div v-for="(item, index) in data1" :key="index">
                <!-- 单选 -->
                <Radio
                  class="itemWrap"
                  v-if="item.is_stuff == 0 && item.retype == 1"
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Radio>
                <!-- 多选 -->
                <Checkbox
                  class="itemWrap"
                  v-if="
                    (item.is_stuff == 0 && item.retype == 2) ||
                    (item.is_stuff == 0 && item.retype == 3)
                  "
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Checkbox>
                <!-- 判断 -->
                <Estimate
                  class="itemWrap"
                  v-if="item.is_stuff == 0 && item.retype == 5"
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Estimate>
                <!-- 论述 -->
                <Discuss
                  class="itemWrap"
                  v-if="item.is_stuff == 0 && item.retype == 4"
                  :list="item"
                  :is_stuff="item.is_stuff"
                  :isDisable="isDisable"
                ></Discuss>
                <!-- 资料 -->
                <Materials
                  class="itemWrap"
                  v-if="item.is_stuff == undefined"
                  :list="item"
                ></Materials>
              </div>
            </div>
          </template>
        </div>
      </main>

    </div>
    <el-dialog
      title="异常行为提醒"
      :visible.sync="abnormityVisible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="600px"
    >
      <p class="fontSize14 lineH24 textAlignC">
        系统监测到异常行为。如果再次出现切屏等异常操作，您将面临被强制收卷的风险。
        <br />请在此页面认真答题！
      </p>
      <div slot="footer" class="textAlignC">
        <el-button type="primary" @click="abnormityVisible = false"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="收卷通知"
      :visible.sync="upVisible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="600px"
    >
      <p class="fontSize14 lineH24 textAlignC">
        您的异常行为次数过多，<span>{{ sec }}</span> 秒后将自动收卷！
      </p>
      <div slot="footer" class="textAlignC">
        <el-button type="primary" @click="submit()">退出考试</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="hintVisible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="600px"
    >
      <p class="fontSize14 lineH24 textAlignC">
        关闭页面不会保存已经填写的作答，是否继续？
      </p>
      <div slot="footer" class="textAlignC">
        <el-button type="info" @click="hintVisible = false">取消</el-button>
        <el-button type="primary">继续</el-button>
      </div>
    </el-dialog>
    <el-dialog
            title="作答说明"
            :visible.sync="zdsmVisible"
            width="600px"
            class="zdsmdialog"
    >
      <div v-html="prevent.matters" class="zdsmdialogdec scroll"></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  paperInfo,
  answerSubmit,
  escalation,
  escalationLog,
  Heartbeat,
  autosubmit,
} from "./assets/api";
import Radio from "./components/Radio"; // 单选
import Checkbox from "./components/Checkbox"; // 多选
import Estimate from "./components/Estimate"; // 判断
import Discuss from "./components/Discuss"; // 论述
import Materials from "./components/Materials"; // 材料
import AnswerSheet from "./components/com/AnswerSheet"; //答题卡
import {getCookie} from "../../../assets/js/cookie";
export default {
  name: "studentsExam",
  components: {
    Radio,
    Checkbox,
    Estimate,
    Discuss,
    Materials,
    AnswerSheet,
  },
  data() {
    return {
      student_info: {},
      countDownTime: 0, //设置默认时间,(单位秒)
      dynamic_time: 0,
      timer: null, //计数器名称,便于后面清除
      timer1: null, //计数器名称,便于后面清除
      timer2: null, //计数器名称,便于后面清除
      timer3: null, //计数器名称,便于后面清除
      timer4: null, //计数器名称,便于后面清除
      timer5: null, //计数器名称,便于后面清除
      timer6:null,
      outNumbre: 0,
      abnormityVisible: false,
      upVisible: false,
      hintVisible: false,
      zdsmVisible:false,
      sec: 3,
      paper_id: 0,
      info1: {},
      isBftrue: false,
      data1: [],
      question_show_list: [],
      start_time: 0,
      prevent: {},
      isDisable: false,
      cut_time: 0,
      isExam: 2, //是否是考试1 作业 2考试
      cpe_id: "", //监考id
      courseType: 1, //1是常规考试，2是小米认证考试
      shitiselsect: 0, //0是非抽题 大于0是抽题
      butshow: true,
      time_limit: 0,
      dynamic_timeFlag:false,
      cook:''
    };
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    clearInterval(this.timer4);
    clearInterval(this.timer5);
    clearInterval(this.timer6);
    document.body.onfocus = null;
    document.body.onblur = null;
    next();
  },
  async created() {
    this.outNumbre = window.sessionStorage.getItem("outNumbre")
      ? window.sessionStorage.getItem("outNumbre")
      : 0;
    if (this.$route.query.courseType) {
      this.courseType = this.$route.query.courseType;
    }
    if (
      this.$route.query.shitiselsect != 0 ||
      this.$route.query.shitiselsect != "" ||
      this.$route.query.shitiselsect != undefined
    ) {
      this.shitiselsect = this.$route.query.shitiselsect;
    }
    if (this.$route.query.isExam != undefined) {
      this.isExam = this.$route.query.isExam;
      if (window.sessionStorage.getItem("info1") != null) {
        this.info1 = JSON.parse(window.sessionStorage.getItem("info1"));
        this.student_info = JSON.parse(
          window.sessionStorage.getItem("student_info")
        );
        this.data1 = JSON.parse(window.sessionStorage.getItem("data1"));
        this.isBftrue = JSON.parse(window.sessionStorage.getItem("isBftrue"));
        this.prevent = JSON.parse(window.sessionStorage.getItem("prevent"));
        this.$emit("requestOver");
      } else {
        this.paperInfo(this.$route.query);
      }
    } else {
      this.$nextTick(() => {
        if (window.sessionStorage.getItem("info1") != null) {
          this.info1 = JSON.parse(window.sessionStorage.getItem("info1"));
          this.student_info = JSON.parse(
            window.sessionStorage.getItem("student_info")
          );
          this.data1 = JSON.parse(window.sessionStorage.getItem("data1"));
          this.isBftrue = JSON.parse(window.sessionStorage.getItem("isBftrue"));
          this.prevent = JSON.parse(window.sessionStorage.getItem("prevent"));
          this.$emit("requestOver");
        } else {
          this.paperInfo(this.$route.query);
        }
      });
    }
    window.addEventListener("beforeunload", this.handleBeforeunload);
    this.paperInfo1(this.$route.query);
  },
  computed: {
    // 计算属性自动计算countDownTime,并转换为倒计时
    countDown() {
      const hour = parseInt((this.countDownTime / (60 * 60)) % 24);
      const minutes = parseInt((this.countDownTime / 60) % 60);
      const seconds = this.countDownTime % 60;
      const h = hour < 10 ? "0" + hour : hour;
      const m = minutes < 10 ? "0" + minutes : minutes;
      const s = seconds < 10 ? "0" + seconds : seconds;
      return `${h}:${m}:${s}`;
    },
    countDowns() {
      const hour = parseInt((this.dynamic_time / (60 * 60)) % 24);
      const minutes = parseInt((this.dynamic_time / 60) % 60);
      const seconds = this.dynamic_time % 60;
      const h = hour < 10 ? "0" + hour : hour;
      const m = minutes < 10 ? "0" + minutes : minutes;
      const s = seconds < 10 ? "0" + seconds : seconds;
      return `${h}:${m}:${s}`;
    },
  },
  beforeMount() {
    if (window.sessionStorage.getItem("exam_time") == null) {
      this.startCountDown();
    } else {
      this.countDownTime = Number(window.sessionStorage.getItem("exam_time"));
      this.startCountDown();
    }
    if (window.sessionStorage.getItem("start_time") != null) {
      this.start_time = window.sessionStorage.getItem("start_time");
    }
    if (window.sessionStorage.getItem("cpe_id") != null && this.isExam == 2) {
      this.cpe_id = window.sessionStorage.getItem("cpe_id");
    }
    if (this.isExam == 2) {
      this.Heartbeat();
    }
  },
  async mounted() {
    this.cook = getCookie('siteid')
    //初始化时读取sessionStorage中储存的数据,有就设置为countDownTime,没有就根据自己逻辑设置countDownTime
    this.$on("requestOver", () => {
      if (this.isExam == 2 && this.prevent.no_screen_cuts == 1) {
        document.body.onfocus = () => {
          if (
            this.prevent.screen_cuts_number == 0 &&
            this.prevent.no_screen_cuts == 1
          ) {
            this.$message.warning("请认真答题！");
          } else if (
            this.prevent.screen_cuts_number > 0 &&
            this.prevent.no_screen_cuts == 1
          ) {
            clearInterval(this.timer3);
            this.outNumbre++;
            window.sessionStorage.setItem("outNumbre", this.outNumbre);
            if (
              this.prevent.screen_cuts_number - this.outNumbre <= 0 &&
              this.prevent.no_screen_cuts == 1
            ) {
              this.upVisible = true;
              this.sec = 3;
              this.timer1 = setInterval(() => {
                this.sec--;
                if (this.sec <= 0) {
                  const params = {
                    course_paper_id: this.$route.query.id,
                    unusual_type: 1,
                    cut_time: this.cut_time,
                  };
                  escalationLog(params).then((res) => {
                    if (res.code == 0) {
                      this.cut_time = 0;
                      clearInterval(this.timer3);
                    }
                  });
                  this.submit(1);
                }
              }, 1000);
            } else {
              // this.$message.warning(`警告！你已经离开当前答题页面${this.outNumbre}`);
              this.escalationLog();
            }
          }
        };
        document.body.onblur = () => {
          this.timer3 = setInterval(() => {
            this.cut_time++;
          }, 1000);
        };
      }
      if (this.isExam == 2) {
        this.stopF5Refresh();
      }
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.handleBeforeunload);
    clearInterval(this.timer);
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    clearInterval(this.timer4);
    clearInterval(this.timer5);
    clearInterval(this.timer6);
    window.sessionStorage.removeItem("info1");
    window.sessionStorage.removeItem("student_info");
    window.sessionStorage.removeItem("cpe_id");
    window.sessionStorage.removeItem("data1");
    window.sessionStorage.removeItem("isBftrue");
    window.sessionStorage.removeItem("exam_time");
    window.sessionStorage.removeItem("start_time");
    window.sessionStorage.removeItem("prevent");
    window.sessionStorage.removeItem("cpe_id");
    window.sessionStorage.removeItem("outNumbre");
    //删除阻止的事件
    window.onbeforeunload = null;
    document.onkeydown = null;
    document.oncontextmenu = null;
    document.onselectstart = null;
    document.body.onfocus = null;
    document.body.onblur = null;
  },
  methods: {
    //异常上报（初始化）
    escalation() {
      const params = {
        course_paper_id: this.$route.query.id,
        cst_id: this.$route.query.cst_id,
      };
      escalation(params).then((res) => {
        this.cpe_id = res.data.cpe_id;
        const data = {
          source_id: this.$route.query.id,
          cpe_id: this.cpe_id,
        };
        Heartbeat(data).then((res) => {});
      });
    },
    //多端禁用上报
    Heartbeat() {
      this.timer2 = setInterval(() => {
        const params = {
          source_id: this.$route.query.id,
          cpe_id: this.cpe_id,
        };
        Heartbeat(params).then((res) => {
          if (res.code == 0) {
            console.log('心跳开始')
            if (res.data.end_time > 0) {
              if(res.data.end_time - res.data.new_time<0){
                this.dynamic_time=0
                // this.dynamic_timeFlag=true
                console.log(this.dynamic_timeFlag,'this.dynamic_timeFlag',111)
              }else{
                this.dynamic_time = res.data.end_time - res.data.new_time;
                console.log(this.dynamic_timeFlag,'this.dynamic_timeFlag',222)
                if(this.dynamic_timeFlag){
                  this.dynamic_timeFlag=false
                  this.info1.new_time=res.data.new_time
                  this.timer6 = setInterval(() => {
                    console.log(this.dynamic_time,333333)
                    if (this.dynamic_time < 1) {
                      this.submit(1);
                      this.dynamic_time = 0;
                      clearInterval(this.timer6);
                    } else {
                      this.dynamic_time--;
                    }
                    if(this.butshow){
                        if (this.info1.new_time < this.info1.min_submission) {
                        this.info1.new_time++;
                      } else {
                        this.butshow = false;
                      }
                    }

                  }, 1000);
                }
              }
            }
          }
        });
      }, 30000);
    },
    //异常日志上报
    escalationLog() {
      const params = {
        course_paper_id: this.$route.query.id,
        unusual_type: 1,
        cut_time: this.cut_time,
      };
      escalationLog(params).then((res) => {
        if (res.code == 0) {
          this.cut_time = 0;
          this.abnormityVisible = true;
          clearInterval(this.timer3);
        }
      });
    },
    //监听刷新保存数据
    handleBeforeunload(event) {
      window.sessionStorage.setItem("info1", JSON.stringify(this.info1));
      window.sessionStorage.setItem(
        "student_info",
        JSON.stringify(this.student_info)
      );
      window.sessionStorage.setItem("data1", JSON.stringify(this.data1));
      window.sessionStorage.setItem("isBftrue", JSON.stringify(this.isBftrue));
      window.sessionStorage.setItem("exam_time", this.countDownTime);
      window.sessionStorage.setItem("prevent", JSON.stringify(this.prevent));
      window.sessionStorage.setItem("cpe_id", this.cpe_id);
      if (window.sessionStorage.getItem("start_time") == null) {
        window.sessionStorage.setItem("start_time", this.start_time);
      }
      event.preventDefault();
    },
    subauto() {
      const params = {
        paper_id: this.$route.query.paper_id,
        answer_id: this.answer_id || 0,
        cst_id: this.$route.query.cst_id,
        course_id: this.$route.query.course_id,
        source_type: 2,
        source_id: this.$route.query.id,
        answer_type: 2,
        preserve_seq: 0,
        start_time: this.start_time,
        answer_list: {},
      };
      if (this.data1.length > 0) {
        const info = this.submitnum(this.data1);
        params.answer_list = JSON.stringify(info[1]);
      }
      autosubmit(params).then((res) => {
        if (res.code == 0) {
          this.answer_id = res.data.answer_id;
          this.start_time = res.data.start_time;
        }
      });
    },
    submit(type = 0) {
      //type  0为主动交卷 1为强制交卷
      const params = {
        paper_id: this.$route.query.paper_id,
        answer_id: this.answer_id||0,
        cst_id: this.$route.query.cst_id,
        course_id: this.$route.query.course_id,
        source_type: this.isExam,
        source_id: this.$route.query.id,
        answer_type: 2,
        answer_status: 1,
        preserve_seq: 0,
        start_time: this.start_time,
        is_force: type,
        answer_list: {},
        uuid: [],
        old_uuid: [],
      };
      if (this.data1.length > 0) {
        const info = this.submitnum(this.data1);
        params.answer_list = JSON.stringify(info[1]);
        if (this.isExam == 1) {
          params.uuid = info[0];
        } else {
          if (this.prevent.no_screen_cuts == 1) {
            delete params.uuid;
          } else {
            params.uuid = info[0];
          }
          delete params.old_uuid;
        }
      }
      if (
        this.courseType == 2 &&
        this.prevent.no_screen_cuts == 0 &&
        type == 0
      ) {
        const paper_questionseq = this.submitnum(this.data1)[2];
        if (paper_questionseq.length > 0) {
          let select = paper_questionseq.find(
            (item) => item.question_seq == this.shitiselsect
          );
          if (select) {
            if (select.content == "") {
              this.$message.error(`请填写${select.label}内容！`);
              return false;
            } else if (select.pic == "") {
              this.$message.error(`请上传${select.label}图片！`);
              return false;
            }
          }
        }
      }
      answerSubmit(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("交卷成功！");
          window.sessionStorage.removeItem("outNumbre");
          if (this.$route.query.courseType == 2) {
            // this.$router.push({
            //   path: "/MyExam",
            //   query: {},
            // });
            console.log(res.data.answer_id, 123);
            this.$router.push({
              path: "/ExamResult",
              query: {
                answer_id: res.data.answer_id,
                paper_id: this.$route.query.paper_id,
                course_paper_id:this.$route.query.id
              },
            });
          } else {
            this.$router.push({
              path: "/newlearn",
              query: {
                cst_id: this.$route.query.cst_id,
                course_id: this.$route.query.course_id,
              },
            });
          }
          window.scrollTo(0, 0);
        }
      });
    },
    submitnum(data1) {
      let paper_uuidarr = [];
      const paper_questionarr = {};
      const paper_questionseq = [];
      // 编辑试卷
      data1.map((item) => {
        let key = "";
        let value = "";
        if (item.type == 1) {
          if (item.children) {
            item.children.map((it) => {
              if (it.type == 3) {
                key = it.retype + "_" + it.id + "_" + it.question_seq;
                if (it.retype == 1 || it.retype == 5) {
                  value = it.radio;
                } else if (it.retype == 2 || it.retype == 3) {
                  value = it.radio.toString();
                } else {
                  let user_pic = [];
                  if (it.user_pic.length > 0) {
                    it.user_pic.map((ii) => {
                      user_pic.push(ii.path);
                    });
                  }
                  const objcon = {
                    content: it.radio,
                    pic: user_pic.length > 0 ? user_pic.toString() : "",
                  };
                  value = objcon;
                  if (paper_uuidarr.length == 0) {
                    paper_uuidarr = user_pic;
                  } else {
                    paper_uuidarr = paper_uuidarr.concat(user_pic);
                  }
                  //仅对小米实验题
                  const obj = {
                    label: it.label,
                    content: it.radio,
                    pic: user_pic.length > 0 ? user_pic.toString() : "",
                    question_seq: it.question_seq,
                  };
                  paper_questionseq.push(obj);
                }
                paper_questionarr[key] = value;
              }
              if (it.children) {
                it.children.map((i) => {
                  key = i.retype + "_" + i.id + "_" + i.question_seq;
                  if (i.retype == 1 || i.retype == 5) {
                    value = i.radio;
                  } else if (i.retype == 2 || i.retype == 3) {
                    value = i.radio.toString();
                  } else {
                    let user_pic = [];
                    if (i.user_pic.length > 0) {
                      i.user_pic.map((ii) => {
                        user_pic.push(ii.path);
                      });
                    }
                    const objcon = {
                      content: i.radio,
                      pic: user_pic.length > 0 ? user_pic.toString() : "",
                    };
                    value = objcon;
                    if (paper_uuidarr.length == 0) {
                      paper_uuidarr = user_pic;
                    } else {
                      paper_uuidarr=paper_uuidarr.concat(user_pic);
                    }
                    //仅对小米实验题
                    const obj = {
                      label: i.label,
                      content: i.radio,
                      pic: user_pic.length > 0 ? user_pic.toString() : "",
                      question_seq: i.question_seq,
                    };
                    paper_questionseq.push(obj);
                  }
                  paper_questionarr[key] = value;
                });
              }
            });
          }
        }
        if (item.type == 2) {
          if (item.children) {
            item.children.map((it) => {
              key = it.retype + "_" + it.id + "_" + it.question_seq;
              if (it.retype == 1 || it.retype == 5) {
                value = it.radio;
              } else if (it.retype == 2 || it.retype == 3) {
                value = it.radio.toString();
              } else {
                let user_pic = [];
                if (it.user_pic.length > 0) {
                  it.user_pic.map((ii) => {
                    user_pic.push(ii.path);
                  });
                }
                const objcon = {
                  content: it.radio,
                  pic: user_pic.length > 0 ? user_pic.toString() : "",
                };
                value = objcon;
                if (paper_uuidarr.length == 0) {
                  paper_uuidarr = user_pic;
                } else {
                  paper_uuidarr=paper_uuidarr.concat(user_pic);
                }
                //仅对小米实验题
                const obj = {
                  label: it.label,
                  content: it.radio,
                  pic: user_pic.length > 0 ? user_pic.toString() : "",
                  question_seq: it.question_seq,
                };
                paper_questionseq.push(obj);
              }
              paper_questionarr[key] = value;
            });
          }
        }
        if (item.type == 3) {
          key = item.retype + "_" + item.id + "_" + item.question_seq;
          if (item.retype == 1 || item.retype == 5) {
            value = item.radio;
          } else if (item.retype == 2 || item.retype == 3) {
            value = item.radio.toString();
          } else {
            let user_pic = [];
            if (item.user_pic.length > 0) {
              item.user_pic.map((ii) => {
                user_pic.push(ii.path);
              });
            }
            const objcon = {
              content: item.radio,
              pic: user_pic.length > 0 ? user_pic.toString() : "",
            };
            value = objcon;
            console.log(user_pic,paper_uuidarr,9887)
            if (paper_uuidarr.length == 0) {
              paper_uuidarr = user_pic;
            } else {
              paper_uuidarr=paper_uuidarr.concat(user_pic);
            }
            //仅对小米实验题
            const obj = {
              label: item.label,
              content: item.radio,
              pic: user_pic.length > 0 ? user_pic.toString() : "",
              question_seq: item.question_seq,
            };
            paper_questionseq.push(obj);
          }
          paper_questionarr[key] = value;
        }
      });
      console.log(paper_uuidarr,123)
      return [paper_uuidarr, paper_questionarr, paper_questionseq];
    },
    stopF5Refresh() {
      console.log(this.prevent);
      //屏蔽F1---F12    124 为f12
      document.onkeydown = function (e) {
        var evt = window.event || e;
        var code = evt.keyCode || evt.which;
        if (code > 111 && code < 124) {
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false;
          }
        }
      };
      //禁止鼠标右键菜单
      document.oncontextmenu = function () {
        return false;
      };
      //阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
      history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, window.location.href);
      });
      console.log(this.prevent);
      //禁止复制
      if (this.prevent.no_copy != 0) {
        this.isDisable = true;
        document.onselectstart = function () {
          return false;
        };
      }
      //监听关闭和刷新
      window.onbeforeunload = (e) => {
        return "";
      };
    },
    startCountDown() {
      this.timer = setInterval(() => {
        if (this.countDownTime <= 0) {
          //当监测到countDownTime为0时,清除计数器并且移除sessionStorage,然后执行提交试卷逻辑
          clearInterval(this.timer);
          sessionStorage.removeItem("exam_time");
          if (this.info1.time_limit > 0) {
            // alert("提交试卷");
            this.submit(1);
          }
        } else if (this.countDownTime > 0) {
          //每秒让countDownTime -1秒,并设置到sessionStorage中
          this.countDownTime--;
          window.sessionStorage.setItem("exam_time", this.countDownTime);
        }
      }, 1000);
    },
    //获取剩余时间
    paperInfo1(data) {
      const params = {
        paper_id: data.paper_id,
        answer_id: 0,
        source_type: this.isExam,
        source_id: data.id,
      };
      paperInfo(params).then((res) => {
        if (res.code == 0) {
          this.answer_id = res.data.answer_id;
          this.time_limit = res.data.paper_show.time_limit;
          this.dynamic_time =
            res.data.paper_show.end_time - res.data.paper_show.new_time; //res.data.paper_show.dynamic_time
          if (this.isExam == 2 && this.time_limit != 0) {
            this.timer4 = setInterval(() => {
              if (this.dynamic_time < 2) {
                this.submit(1);
                this.dynamic_time = 0;
                clearInterval(this.timer4);
                this.dynamic_timeFlag=true
              } else {
                this.dynamic_time--;
              }
              // this.info1.new_time++;
              if (this.info1.new_time < this.info1.min_submission) {
                this.info1.new_time++;
              } else {
                this.butshow = false;
              }
            }, 1000);
            this.timer5 = setInterval(() => {
              this.subauto();
            }, (Math.floor(Math.random() * 17) + 54) * 1000); //(Math.floor(Math.random() * 17) + 54)*1000
          } else {
            this.butshow = false;
          }
        }
      });
    },
    // 获取试卷信息
    paperInfo(data) {
      const params = {
        paper_id: data.paper_id,
        answer_id: 0,
        source_type: this.isExam,
        source_id: data.id,
      };
      paperInfo(params).then((res) => {
        if (res.code == 0) {
          this.info1 = res.data.paper_show;
          this.prevent = res.data.prevent;
          window.sessionStorage.setItem("prevent", JSON.stringify(this.prevent));
          this.student_info = res.data.student_info || 1;
          this.countDownTime = Number(res.data.paper_show.time_limit) * 60;
          this.start_time = Number(this.info1.new_time);
          this.numZc(
            this.info1.paper_part,
            this.info1.stuff_info,
            this.info1.question_show_list
          );
          this.$emit("requestOver");
          if (this.isExam == 2) {
            if (window.sessionStorage.getItem("cpe_id")) {
              this.cpe_id = window.sessionStorage.getItem("cpe_id");
              const data = {
                source_id: this.$route.query.id,
                cpe_id: this.cpe_id,
              };
              Heartbeat(data).then((res) => {});
            } else {
              this.escalation();
            }
          }
        } else {
          if (this.$route.query.courseType == 2) {
            this.$router.push({
              path: "/MyExam",
              query: {},
            });
          } else {
            this.$router.push({
              path: "/newlearn",
              query: {
                cst_id: this.$route.query.cst_id,
                course_id: this.$route.query.course_id,
              },
            });
          }
        }
      });
    },
    // 判断是否有部分
    isHavaBf(data) {
      this.isBftrue = data.some((item) => item.type == 1);
    },
    numZc(paper_part, stuff_info, question_show_list, istype = false) {
      if (question_show_list.length > 0) {
        question_show_list.map((questionshowlistitem) => {
          this.$set(questionshowlistitem, "type", 3);
          this.$set(questionshowlistitem, "isExam", this.isExam);
          if (questionshowlistitem.user_pic == null) {
            this.$set(questionshowlistitem, "user_pic", []);
          }
          if (
            questionshowlistitem.retype == 2 ||
            questionshowlistitem.retype == 3
          ) {
            if (questionshowlistitem.user_answer != "") {
              let ss = questionshowlistitem.user_answer.split(",");
              this.$set(questionshowlistitem, "radio", ss);
            } else {
              this.$set(questionshowlistitem, "radio", []);
            }
            if (questionshowlistitem.user_answer != "") {
              console.log(questionshowlistitem, 123);
            }
          } else if (
            questionshowlistitem.retype == 1 ||
            questionshowlistitem.retype == 5
          ) {
            this.$set(
              questionshowlistitem,
              "radio",
              questionshowlistitem.user_answer
            );
            if (questionshowlistitem.user_answer != "") {
              console.log(questionshowlistitem, 234);
            }
          } else {
            this.$set(
              questionshowlistitem,
              "radio",
              questionshowlistitem.user_answer
            );
            if (questionshowlistitem.user_answer != "") {
              this.$set(questionshowlistitem, "right", 3);
            }
          }
        });
      }
      if (stuff_info.length > 0) {
        stuff_info.map((stuffinfoitem) => {
          this.$set(stuffinfoitem, "type", 2);
          this.$set(stuffinfoitem, "children", []);
          if (question_show_list.length > 0) {
            question_show_list.map((questionshowlistitem) => {
              if (stuffinfoitem.id == questionshowlistitem.stuff_id) {
                this.$set(
                  stuffinfoitem,
                  "part_id",
                  questionshowlistitem.part_id
                );
              }
            });
          }
        });
      }
      if (paper_part != null && paper_part.length > 0) {
        paper_part.map((paperpartitem) => {
          this.$set(paperpartitem, "type", 1);
          this.$set(paperpartitem, "children", []);
          if (question_show_list.length > 0) {
            question_show_list.map((questionshowlistitem) => {
              if (questionshowlistitem.is_stuff == 1) {
                stuff_info.map((stuffinfoitem) => {
                  if (questionshowlistitem.stuff_id == stuffinfoitem.id) {
                    this.$set(
                      questionshowlistitem,
                      "content",
                      stuffinfoitem.content
                    );
                  }
                });
              }
              if (questionshowlistitem.part_id == paperpartitem.id) {
                if (questionshowlistitem.is_stuff == 1) {
                  const isarr = paperpartitem.children.some(
                    (itt) =>
                      itt.id == questionshowlistitem.stuff_id && itt.type == 2
                  );
                  const findindex = paperpartitem.children.findIndex(
                    (itt) =>
                      itt.id == questionshowlistitem.stuff_id && itt.type == 2
                  );
                  if (!isarr) {
                    const obj = {
                      children: [],
                      id: questionshowlistitem.stuff_id,
                      label: "",
                      type: 2,
                      content: questionshowlistitem.content,
                    };
                    if (istype) {
                      obj.addnew = true;
                    }
                    if (this.shitiselsect > 0) {
                      if (
                        questionshowlistitem.question_seq == this.shitiselsect
                      ) {
                        obj.isHow = true;
                      } else {
                        obj.isHow = false;
                      }
                    }
                    obj.children.push(questionshowlistitem);
                    paperpartitem.children.push(obj);
                  } else {
                    if (this.shitiselsect > 0) {
                      if (
                        questionshowlistitem.question_seq == this.shitiselsect
                      ) {
                        if (!paperpartitem.children[findindex].isHow) {
                          paperpartitem.children[findindex].isHow = true;
                        }
                      } else {
                        if (paperpartitem.children[findindex].isHow) {
                        } else {
                          paperpartitem.children[findindex].isHow = false;
                        }
                      }
                    }
                    paperpartitem.children[findindex].children.push(
                      questionshowlistitem
                    );
                  }
                } else {
                  paperpartitem.children.push(questionshowlistitem);
                }
              }
            });
            if (this.shitiselsect > 0) {
              const isTrue = question_show_list.find(
                (itt) => itt.question_seq == this.shitiselsect
              ).part_id;
              if (paperpartitem.id == isTrue) {
                this.$set(paperpartitem, "isHow", true);
              } else {
                this.$set(paperpartitem, "isHow", false);
              }
            }
          }
        });
        setTimeout(() => {
          this.data1 = this.numcl(paper_part);
        }, 50);
      } else {
        const arrdata = [];
        if (question_show_list != null && question_show_list.length > 0) {
          question_show_list.map((questionshowlistitem) => {
            if (questionshowlistitem.is_stuff == 1) {
              stuff_info.map((stuffinfoitem) => {
                if (questionshowlistitem.stuff_id == stuffinfoitem.id) {
                  this.$set(
                    questionshowlistitem,
                    "content",
                    stuffinfoitem.content
                  );
                }
              });
            }
            if (questionshowlistitem.is_stuff == 1) {
              const isarr = arrdata.some(
                (itt) =>
                  itt.id == questionshowlistitem.stuff_id && itt.type == 2
              );
              const findindex = arrdata.findIndex(
                (itt) =>
                  itt.id == questionshowlistitem.stuff_id && itt.type == 2
              );
              if (!isarr) {
                const obj = {
                  children: [],
                  id: questionshowlistitem.stuff_id,
                  label: "",
                  type: 2,
                  content: questionshowlistitem.content,
                };
                if (this.shitiselsect > 0) {
                  if (questionshowlistitem.question_seq == this.shitiselsect) {
                    obj.isHow = true;
                  } else {
                    obj.isHow = false;
                  }
                }
                obj.children.push(questionshowlistitem);
                arrdata.push(obj);
              } else {
                if (this.shitiselsect > 0) {
                  if (questionshowlistitem.question_seq == this.shitiselsect) {
                    if (!arrdata[findindex].isHow) {
                      arrdata[findindex].isHow = true;
                    }
                  } else {
                    if (arrdata[findindex].isHow) {
                    } else {
                      arrdata[findindex].isHow = false;
                    }
                  }
                }
                arrdata[findindex].children.push(questionshowlistitem);
              }
            } else {
              arrdata.push(questionshowlistitem);
            }
          });
        }
        setTimeout(() => {
          this.data1 = this.numcl(arrdata);
        }, 30);
      }
    },
    numcl(data, type = false, listitem) {
      let cailiaoindex = 0;
      let shitiindex = 0;
      this.total_score = 0;
      this.question_num = 0;
      data.map((item, index) => {
        if (item.type == 1) {
          let question_count = 0;
          let part_total_score = 0;
          this.$set(item, "seq", "bf-" + (index + 1));
          if (item.children.length > 0) {
            item.children.map((it) => {
              if (it.type == 3) {
                shitiindex++;
                question_count++;
                if (type) {
                  if (
                    Number(listitem.input1) <=
                    shitiindex <=
                    Number(listitem.input2)
                  ) {
                    this.$set(it, "score", Number(listitem.input3));
                  }
                }
                part_total_score += Number(it.score);
                this.total_score += Number(it.score);
                this.question_num++;
                this.$set(it, "seq", "st-" + shitiindex);
                this.$set(it, "label",  shitiindex);
                this.$set(it, "clid", "");
                this.$set(it, "part_id", item.id);
                this.$set(item, "question_count", question_count);
                this.$set(item, "part_total_score", part_total_score);
                this.$set(it, "part_seq", index + 1);
                this.$set(it, "stuff_seq", 0);
              }
              if (it.type == 2) {
                cailiaoindex++;
                this.$set(it, "seq", "cl-" + cailiaoindex);
                this.$set(it, "label", "材料" + cailiaoindex);
              }
              this.$set(it, "bfid", item.id);
              this.$set(item, "part_id", item.id);
              if (it.children != undefined) {
                it.children.map((i) => {
                  shitiindex++;
                  question_count++;
                  if (type) {
                    if (
                      Number(listitem.input1) <=
                      shitiindex <=
                      Number(listitem.input2)
                    ) {
                      this.$set(i, "score", Number(listitem.input3));
                    }
                  }
                  part_total_score += Number(i.score);
                  this.total_score += Number(i.score);
                  this.question_num++;
                  this.$set(i, "seq", "st-" + shitiindex);
                  this.$set(i, "label",  shitiindex);
                  this.$set(item, "question_count", question_count);
                  this.$set(item, "part_total_score", part_total_score);
                  this.$set(i, "bfid", item.id);
                  this.$set(i, "clid", it.id);
                  this.$set(i, "part_id", item.id);
                  this.$set(i, "stuff_id", it.id);
                  this.$set(i, "part_seq", index + 1);
                  this.$set(i, "stuff_seq", cailiaoindex);
                  this.$set(i, "is_stuff", 1);
                });
              }
            });
            const ishaschild = item.children.some((t) => t.children > 0); // 判断是否有试题
            if (ishaschild) {
              this.$set(item, "question_count", 0);
              this.$set(item, "part_total_score", 0);
            }
          } else {
            this.$set(item, "question_count", question_count);
            this.$set(item, "part_total_score", part_total_score);
          }
          // this.$set(item, 'label', '第' + this.toChinese(index + 1) + '部分（共' + item.question_count + '题，' + item.part_total_score + '分）')
        }
        if (item.type == 2) {
          if (item.children) {
            cailiaoindex++;
            this.$set(item, "seq", "cl-" + cailiaoindex);
            this.$set(item, "label", "材料" + cailiaoindex);
            item.children.map((it) => {
              shitiindex++;
              if (type) {
                if (
                  Number(listitem.input1) <=
                  shitiindex <=
                  Number(listitem.input2)
                ) {
                  this.$set(it, "score", Number(listitem.input3));
                }
              }
              this.total_score += Number(it.score);
              this.question_num++;
              this.$set(it, "seq", "st-" + shitiindex);
              this.$set(it, "label",  shitiindex);
              this.$set(it, "clid", item.id);
              this.$set(it, "part_id", 0);
              this.$set(it, "stuff_id", item.id);
              this.$set(it, "part_seq", 0);
              this.$set(it, "stuff_seq", cailiaoindex);
              this.$set(it, "is_stuff", 1);
            });
          }
        }
        if (item.type == 3) {
          shitiindex++;
          if (type) {
            if (
              Number(listitem.input1) <=
              shitiindex <=
              Number(listitem.input2)
            ) {
              this.$set(item, "score", Number(listitem.input3));
            }
          }
          this.total_score += Number(item.score);
          this.question_num++;
          this.$set(item, "seq", "st-" + shitiindex);
          this.$set(item, "label",  shitiindex);
          this.$set(item, "part_seq", 0);
          this.$set(item, "stuff_seq", 0);
          this.$set(item, "part_id", 0);
          this.$set(item, "stuff_id", 0);
        }
      });
      this.isHavaBf(data);
      return data;
    },
  },
};
</script>
<style lang="scss">
  .zdsmdialog{
    .el-dialog__title{
      font-size: 13px;
      color: #333333;
    }
  }
  .zdsmdialogdec{
    font-size: 13px;
    color: #333333;
    line-height: 30px;
    max-height: 500px;
    overflow: auto;
    img{
      max-width: 100%;
    }
  }
</style>
<style scoped lang="scss">

  aside{
    width: 240px;
    float: left; margin-right: 20px;position: fixed; display: flex; flex-direction: column; height:calc(100% - 92px);
  }
.stinfo {
  width: 240px; height: 157px;
  border-radius: 8px;
  background: #fff; margin-bottom: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
  dt {
    height: 40px;
    padding-left: 10px;
    border-radius: 8px 8px 0 0;
    line-height: 40px;
    color: #fff;
    background: #507fff;
    font-size: 16px;
    font-weight: bold;
  }
  dd {
    padding: 10px; box-sizing: border-box;
    font-size: 13px;
    line-height: 24px;
    .pic {
      float: left; width:65px; height: 90px; margin-right: 15px;
      }
    .picxx{ float: left; width: 140px;
      span {
        color: #707070;
        display: block;
        i {
          font-style: normal;
          color: #333;
        }
    }
    }

  }
}

.topFix {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 20px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  button {
    background: #c8c9cc;
    border-color: #c8c9cc;
    padding: 10px 30px;
    font-size: 14px;
    line-height: 18px;
  }
  .uxbicon_side_time {
    color: #507fff;
    font-size: 20px;
    margin-right: 8px;
  }
}
.contMain {
  max-width: 1600px; min-width: 1360px;
  position: relative;
  margin: 0px auto 20px; padding: 92px 20px 0;
  main{
    float: right; width: calc(100% - 260px);
  }
  .types {
    padding: 13px 30px;
    background: #FAFAFA;
    line-height: 26px;
    b {
      line-height: 26px;
      font-size: 18px;
    }
  }
  .intr {
    font-size: 16px; background: #FAFAFA;
    line-height: 28px;
    padding: 0 30px 20px;
  }
  .testQuestions {

    background: #fff;
    border-radius: 3px;
    padding: 1px 30px;
  }
}
/deep/.el-dialog__header {
  border-bottom: solid 1px #e9ecef;
  padding-bottom: 16px;
}
  .el-button--primary{ background:#FF631E!important; }
</style>
