var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.student_info!=1?'answerWrap':'answerWrap answerWraps'},[_c('div',{staticClass:"answerBtn",on:{"click":_vm.upOrDown}},[_vm._m(0),(_vm.prevent.matters!='')?_c('span',{staticClass:"answerBtnzdsm",on:{"click":_vm.zdsmshow}},[_vm._v("作答说明"),_c('i',{staticClass:"iconfont icon-zdsm"})]):_vm._e()]),_c('div',{staticClass:"answerList scroll"},[(_vm.$route.query.shitiselsect > 0)?[(_vm.type)?_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerData),function(ite,inde){return _c('div',{key:inde},[(ite.isHow)?_c('h2',{staticClass:"fontSize16 pb14"},[_vm._v(_vm._s(ite.part_title))]):_vm._e(),_vm._l((ite.children),function(item,index){return _c('div',{key:index,staticStyle:{"display":"inline"}},[(!item.children)?[(
                  _vm.$route.query.shitiselsect != 0 &&
                  item.question_seq == _vm.$route.query.shitiselsect
                )?_c('em',{class:{
                  checkColor:
                    ((item.retype == 1 || item.retype == 5) && item.radio) ||
                    ((item.retype == 2 || item.retype == 3) &&
                      item.radio.length > 0) ||
                    (item.retype == 4 &&
                      (item.radio || item.user_pic.length > 0)),
                },on:{"click":function($event){return _vm.jump(item.seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" ")]):_vm._e()]:_vm._e(),(item.children)?_vm._l((item.children),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[(
                    _vm.$route.query.shitiselsect != 0 &&
                    item1.question_seq == _vm.$route.query.shitiselsect
                  )?_c('em',{class:{
                    checkColor:
                      ((item1.retype == 1 || item1.retype == 5) &&
                        item1.radio) ||
                      ((item1.retype == 2 || item1.retype == 3) &&
                        item1.radio.length > 0) ||
                      (item1.retype == 4 &&
                        (item1.radio || item1.user_pic.length > 0)),
                  },on:{"click":function($event){return _vm.jump(item1.seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" ")]):_vm._e()])}):_vm._e()],2)})],2)}),0):_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerData),function(item,index){return _c('span',{key:index},[(!item.children)?[(
                _vm.$route.query.shitiselsect != 0 &&
                item.question_seq == _vm.$route.query.shitiselsect
              )?_c('em',{class:{
                checkColor:
                  ((item.retype == 1 || item.retype == 5) && item.radio) ||
                  ((item.retype == 2 || item.retype == 3) &&
                    item.radio.length > 0) ||
                  (item.retype == 4 &&
                    (item.radio || item.user_pic.length > 0)),
              },on:{"click":function($event){return _vm.jump(item.seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" ")]):_vm._e()]:_vm._e(),(item.children)?_vm._l((item.children),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[(
                  _vm.$route.query.shitiselsect != 0 &&
                  item1.question_seq == _vm.$route.query.shitiselsect
                )?_c('em',{class:{
                  checkColor:
                    ((item1.retype == 1 || item1.retype == 5) &&
                      item1.radio) ||
                    ((item1.retype == 2 || item1.retype == 3) &&
                      item1.radio.length > 0) ||
                    (item1.retype == 4 &&
                      (item1.radio || item1.user_pic.length > 0)),
                },on:{"click":function($event){return _vm.jump(item1.seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" ")]):_vm._e()])}):_vm._e()],2)}),0)]:[(_vm.type)?_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerData),function(ite,inde){return _c('div',{key:inde},[_c('h2',{staticClass:"fontSize16 pb14"},[_vm._v(_vm._s(ite.part_title))]),_vm._l((ite.children),function(item,index){return _c('div',{key:index,staticStyle:{"display":"inline"}},[(!item.children)?[_c('em',{class:{
                  checkColor:
                    ((item.retype == 1 || item.retype == 5) && item.radio) ||
                    ((item.retype == 2 || item.retype == 3) &&
                      item.radio.length > 0) ||
                    (item.retype == 4 &&
                      (item.radio || item.user_pic.length > 0)),
                },on:{"click":function($event){return _vm.jump(item.seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" ")])]:_vm._e(),(item.children)?_vm._l((item.children),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{class:{
                    checkColor:
                      ((item1.retype == 1 || item1.retype == 5) &&
                        item1.radio) ||
                      ((item1.retype == 2 || item1.retype == 3) &&
                        item1.radio.length > 0) ||
                      (item1.retype == 4 &&
                        (item1.radio || item1.user_pic.length > 0)),
                  },on:{"click":function($event){return _vm.jump(item1.seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" ")])])}):_vm._e()],2)})],2)}),0):_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerData),function(item,index){return _c('span',{key:index},[(!item.children)?[_c('em',{class:{
                checkColor:
                  ((item.retype == 1 || item.retype == 5) && item.radio) ||
                  ((item.retype == 2 || item.retype == 3) &&
                    item.radio.length > 0) ||
                  (item.retype == 4 &&
                    (item.radio || item.user_pic.length > 0)),
              },on:{"click":function($event){return _vm.jump(item.seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" ")])]:_vm._e(),(item.children)?_vm._l((item.children),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{class:{
                  checkColor:
                    ((item1.retype == 1 || item1.retype == 5) &&
                      item1.radio) ||
                    ((item1.retype == 2 || item1.retype == 3) &&
                      item1.radio.length > 0) ||
                    (item1.retype == 4 &&
                      (item1.radio || item1.user_pic.length > 0)),
                },on:{"click":function($event){return _vm.jump(item1.seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" ")])])}):_vm._e()],2)}),0)]],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('i',{staticClass:"leftIcon"},[_c('img',{attrs:{"src":require("../../assets/images/icon_dp.png"),"alt":""}})]),_vm._v(" 答题卡 ")])}]

export { render, staticRenderFns }